import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";

function BackButton() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Button variant="outline-secondary me-1"  className='backButton-indent' onClick={handleBackClick}> <IoMdArrowRoundBack /> Back</Button>
  );
}

export default BackButton;
