import { Spinner } from "react-bootstrap"

function UnversalSpinner(){

    return(
            <div className='divFlexSpan'>
                    <Spinner animation="border" role="status" variant="success">
                                <span className="visually-hidden"><b>Loading...</b></span>
                    </Spinner>
            </div>
    );
}

export default UnversalSpinner;