import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import BackButton from './BackButton';
import UnversalSpinner from './CommonUtilites/UniversalSpinner';

function CreateAccount() {

  const [loading, setLoading] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [passwordMatched, setPasswordMatched] = useState(true);
  const [passwordLength, setPasswordLength] = useState(true);
  const [responseData, setResponseData] = useState("");
  const [accId, setAccId] = useState("");
  const [accPwd, setAccPwd] = useState("");
  const [accConfirmPwd, setAccConfirmPwd] = useState("");

  const handleAccountId = (e) => {
    setAccId(e.target.value);
  };

  const handleAccPwd = (e) => {
    let input = String(e.target.value);
    setAccPwd(input);
    setPasswordLength(false);
    if(input.length >= 6 && input.length <= 10 ){
      checkPassword(input, accConfirmPwd);
      setPasswordLength(true);
    }else{
      setEnableSubmit(false);
    }
  };

  const handleAccConfirmPwd = (e) => {
    let input = String(e.target.value);
    setAccConfirmPwd(input);
    setPasswordLength(false);
    if(input.length >= 6 && input.length <= 10 ){
      checkPassword(accPwd, input);
      setPasswordLength(true);
    }else{
      setEnableSubmit(false);
    }
  };

  function checkPassword(accPwd, accConfirmPwd) {
    if((accPwd.length >= 6 && accPwd.length <= 10 ) && (accConfirmPwd.length >= 6 && accConfirmPwd.length <= 10)){
      if (accPwd === accConfirmPwd && accPwd.length === accConfirmPwd.length) {
        setPasswordMatched(true);
        setEnableSubmit(true);
      } else {
        setPasswordMatched(false);
        setEnableSubmit(false);
      }
    }
  }

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('http://13.233.178.111:8080/createUserAccount', {
        mobileNumber: accId,
        password: accPwd,
      });
      if(response.data){
        setLoading(false);
        setResponseData(response.data);
        handleAccountId('');
        setAccPwd('');
        setAccConfirmPwd('');
      }
    } catch (error) {
      console.error('Error during account creation:', error);
    }
  };

  return (
    <div>
      <Form className="login-container-frame">
        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>LoginID / Mobile Number</Form.Label>
          <Form.Control
            placeholder="Mobile Number"
            value={accId}
            onChange={handleAccountId}
          />
        </Form.Group>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={accPwd}
              onChange={handleAccPwd}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPasswordConfirm">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={accConfirmPwd}
              onChange={handleAccConfirmPwd}
            />
          </Form.Group>
        </Row>

        <Form.Group className="mb-3" id="formGridCheckbox">
          <Form.Text>
            {passwordLength ? "" : <div className="error-msg">Password lenght should be between 6 to 10 Digits</div>}
            {passwordMatched ? "" : <div className="error-msg">Password Not Matched...!</div>}
          </Form.Text>
        </Form.Group>
        <div style={{color:'green'}}>
          { loading ? 
            <UnversalSpinner /> 
          :
            <>
              { responseData ? (<b>{responseData}</b>) : <> </> }
            </>
          }
        </div>
        <Button variant="primary" type="submit" onClick={handleCreateAccount} disabled={!enableSubmit}>
          Submit
        </Button>

        <BackButton />
      </Form>
    </div>
  );
}

export default CreateAccount;
