import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Layout from './Application_pages/Layout';
import NotFound from './Application_pages/CommonSharedFiles/NotFound';
import Home from './Application_pages/LogInPage/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LogInCheck from './Application_pages/LogInCheck';
import AddItem from './Application_pages/AddItems';
import ViewItems from './Application_pages/ViewItems';
import CreateAccount from './Application_pages/CreateAccount';
import AddToCart from './Application_pages/AddToCart';
import ActiveOrdersList from './Application_pages/ActiveOrdersList.js';
import OrderFullDetails from './Application_pages/OrderFullDetails';
import ContactUs from './Application_pages/ContactUs/ContactUs';
import AdminServices from './Application_pages/AdminServices/AdminOverview.js';
import AboutUs from './Application_pages/AboutUs/AboutUs';
import AddNewAgent from './Application_pages/AgentService/AddNewAgent.js';
import ActiveAgents from './Application_pages/AgentService/ActiveAgents.js';
import AllAgentsList from './Application_pages/AgentService/AllAgentsList.js';
import TotalOrdersList from './Application_pages/TotalOrdersList.js';
import PrivateSecureRoute from './Application_pages/Security/PrivateSecureRoute.js';
import { AuthProvider } from './Application_pages/Security/AuthContext.js';
import AddNewCustomer from './Application_pages/CustomerService/NewCustomer.js';
import AdminNewServices from './Application_pages/AdminServices/AdminNewServices.js';
import AdminActiveServices from './Application_pages/AdminServices/AdminActiveServices.js';
import AdminDetailServices from './Application_pages/AdminServices/AdminDetailServices.js';
import AllCustomersList from './Application_pages/CustomerService/AllCustomersList.js';
import CustomerFullDetails from './Application_pages/CustomerService/CustomerFullDetails.js';
import CustomerOrderHistory from './Application_pages/CustomerService/CustomerOrderHistory.jsx';

// Configure the router with routes and their components
const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'login', 
        element: <LogInCheck />
      },
      {
        path: 'createAccount', 
        element: <CreateAccount />
      },
      {
        index: true,
        element: <ViewItems />
      },
      {
        path: 'orderFullDetails',
        element: <OrderFullDetails />
      },
      {
        path: 'addNewCustomer',
        element: <AddNewCustomer />
      },
      {
        path: 'orderHistory',
        element: <CustomerOrderHistory />
      },
      {
        path: 'adminServices',
        element: <PrivateSecureRoute element={<AdminServices />} />
      },
      {
        path: 'addItem',  // Removed leading slash
        element: <PrivateSecureRoute element={<AddItem />} />
      },
      {
        path: 'activeAgents',
        element: <PrivateSecureRoute element={<ActiveAgents />} />
      },
      {
        path: 'allAgentsList',
        element: <PrivateSecureRoute element={<AllAgentsList />} />
      },
      {
        path: "addNewAgent",
        element: <PrivateSecureRoute element={<AddNewAgent />} />

      },
      {
        path: 'addToCart', 
        element: <PrivateSecureRoute element={<AddToCart />} />
      },
      {
        path: 'activeOrdersList',
        element: <PrivateSecureRoute element={<ActiveOrdersList />} />
       },
       {
        path: 'totalOrdersList',
        element: <PrivateSecureRoute element={<TotalOrdersList />} />
       },
      {
        path:"adminNewServices",
        element:<PrivateSecureRoute element={<AdminNewServices />} />
      },
      {
        path:"adminActiveServices",
        element:<PrivateSecureRoute element={<AdminActiveServices />} />
      },
      {
        path:"adminDetailServices",
        element:<PrivateSecureRoute element={<AdminDetailServices />} />
      },
      {
        path:"allCustomersList",
        element:<PrivateSecureRoute element={<AllCustomersList />} />
      },
      {
        path:"customerFullDetails",
        element:<PrivateSecureRoute element={<CustomerFullDetails />} />
      },
      {
        path:'contactUs',
        element: <ContactUs />
      },
      {
        path:'aboutUs',
        element: <AboutUs />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
