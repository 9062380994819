import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import { Button, Form, InputGroup, Container } from 'react-bootstrap';
import ItemCard from './ItemCard';
import AddToCart from './AddToCart';
import TostUtil from './CommonUtilites/TostUtil';
import UnversalSpinner from './CommonUtilites/UniversalSpinner';
import { FaCartArrowDown } from 'react-icons/fa';
import ItemsCanvas from '../components/ItemsCanvas';

const CACHE_KEY = 'itemsListCache';
const CACHE_EXPIRATION_MINUTES = 10;

function ViewItems() {
  const [itemsList, setItemsList] = useState([]);
  const [searchedItem, setSearchedItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [showTost, setShowTost] = useState(false);
  const [tostColor, setTostColor] = useState('success');
  const [cartNxt, setCartNxt] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const fetchItemsList = async () => {
      try {
        const cachedData = localStorage.getItem(CACHE_KEY);
        const now = new Date().getTime();

        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          const isCacheValid = now - timestamp < CACHE_EXPIRATION_MINUTES * 60 * 1000;

          if (isCacheValid) {
            setItemsList(data);
            setSearchedItem(data);
            setLoading(false);
            return; // Exit early since we're using cached data
          }
        }

        const response = await axios.get('http://13.233.178.111:8080/getItemsList');
        if (isMounted) {
          setItemsList(response.data);
          setSearchedItem(response.data);
          localStorage.setItem(CACHE_KEY, JSON.stringify({ data: response.data, timestamp: now }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchItemsList();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!loading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  const addItem = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
    setSelectedName(item.name);
    setShowTost(true);
    setTostColor('success');
  };

  const removeItem = (itemId) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => {
        if (item.itemId !== itemId) {
          return item;
        } else {
          setSelectedName(item.name);
          setShowTost(true);
          setTostColor('danger');
        }
        return null;
      })
    );
  };

  const handleCartItems = () => {
    setCartNxt(true);
  };

  const handleBack = () => {
    setCartNxt(false);
    setSearchedItem(itemsList);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchedItem(
      itemsList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      )
    );
  };

  const clearSearch = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      handleSearch({ target: { value: '' } });
    }
  };

  const handleViewDetails = (item) => {
    setSelectedItem(item);
  };

  const handleCloseCanvas = () => {
    setSelectedItem(null);
  };

  const handleAddToCart = (item) => {
    addItem(item);
  };

  return (
    <Container style={{ marginTop: '0px' }}>
      {showTost && (
        <TostUtil
          name={selectedName}
          showProp={showTost}
          bgColor={tostColor}
          onClose={() => setShowTost(false)}
        />
      )}

      {!cartNxt ? (
        <>
          {loading ? (
            <div style={{ marginTop: '10px' }}>
              <UnversalSpinner />
            </div>
          ) : (
            <>
              <div style={{ position: 'sticky', top: '70px', zIndex: 1020, backgroundColor: 'white', paddingTop: '10px', marginTop: '10px' }}>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Search for Products"
                    aria-label="Search for Products"
                    aria-describedby="search-button"
                    onChange={handleSearch}
                    ref={searchInputRef}
                    style={{ borderColor: 'green' }} // Green border color
                  />
                  <Button
                    variant="secondary"
                    id="search-button"
                    style={{ padding: '1px', minWidth: '12vw' }}
                    onClick={clearSearch}
                  >
                    <strong>Clear</strong>
                  </Button>
                </InputGroup>
              </div>

              <Row xs={2} sm={2} md={3} lg={4} xl={6} className="g-4" style={{ marginTop: '3px' }}>
                {searchedItem.length !== 0 ? (
                  <ItemCard
                    items={searchedItem}
                    addItem={addItem}
                    removeItem={removeItem}
                    selectedItems={selectedItems}
                    onViewDetails={handleViewDetails}
                  />
                ) : (
                  <div className="divFlexSpan" style={{textAlign:'center'}}>
                    <center>
                      <strong>No Products to Display</strong>
                    </center>
                  </div>
                )}
              </Row>

              {selectedItems.length > 0 && (
                <div className="fixed-button-right" style={{ padding: '0px' }}>
                  <Button
                    variant="primary"
                    onClick={handleCartItems}
                  >
                    <FaCartArrowDown style={{ marginRight: '5px' }} />
                    Add to Cart ( {selectedItems.length} )
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <AddToCart cartItems={selectedItems} back={handleBack} />
      )}

      <ItemsCanvas
        item={selectedItem}
        onClose={handleCloseCanvas}
        onAddToCart={handleAddToCart}
      />
    </Container>
  );
}

export default ViewItems;
