// MapComponent.js
import React from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = () => {
  // Example coordinates (lat, lng)
  const route = [
    [51.505, -0.09], // London
    [48.8566, 2.3522], // Paris
    [41.9028, 12.4964], // Rome
  ];

  return (
    <MapContainer center={[18.5208656,78.6797549]} zoom={5} style={{ height: '60vh', width: '80%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
      />
      <Polyline positions={route} color="blue" />
    </MapContainer>
  );
};

export default MapComponent;
