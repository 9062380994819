import {Container, Row } from "react-bootstrap";
import BackButton from "../BackButton";
import { useEffect, useState } from "react";
import axios from "axios";
import PersonTemplate from "./PersonTemplate";
import UnversalSpinner from "../CommonUtilites/UniversalSpinner";

function AllAgentsList() {

  const [agentsList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAgentsList = async () => {
      try {
        const response = await axios.get('http://13.233.178.111:8080/getAllAgents');
        if (response.data) {
          console.log(response.data);
          setAgentList(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAgentsList();
  }, []);

  return (
    <div style={{marginTop:'30px'}}>
      {loading ? <UnversalSpinner />
        :
        <>
        <Container>
          <Row className="d-flex justify-content-start">
            {agentsList.map((agent, index) => (
              agent != null ? <PersonTemplate key={index} details={agent} /> : ''
            ))}
          </Row>
        </Container>

        <div className='divFlexSpan'>
          <BackButton />
        </div>
        </>
      }
    </div>
  );
}

export default AllAgentsList;
