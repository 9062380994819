import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HomePageCarosel from '../HomePageCarosel.js';
import HomePageContent from '../home/HomePageContent.jsx';

function Home() {
  return (
    
      <Container fluid>
        <Row> 
          <Col style={{padding:'10px 0px', marginTop:'4rem'}}>
            <HomePageCarosel />
          </Col>
        </Row>
        <Row>
          <Col style={{padding:'10px 0px', marginTop:'4rem'}}>
            <HomePageContent />
          </Col>
        </Row>
      </Container>
  );
}

export default Home;
