import Carousel from 'react-bootstrap/Carousel';
import Image1 from '../assets/image1.png';
import Image2 from '../assets/image2.png';
import Image3 from '../assets/image3.png';

function HomePageCarosel() {
   return (
    <div style={{height:'250px', width:'100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Carousel data-bs-theme="dark" style={{ width: '100%' }}>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image1}
                height={'360px'}
                alt="First slide"
                />
                <Carousel.Caption>
                <h5 style={{color:"yellow"}}>First slide label</h5>
                <p style={{color:"yellow"}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image2}
                height={'360px'}
                alt="Second slide"
                />
                <Carousel.Caption>
                <h5 style={{color:"yellow"}}>Second slide label</h5>
                <p style={{color:"yellow"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image3}
                height={'360px'}
                alt="Third slide"
                />
                <Carousel.Caption>
                <h5 style={{color:"yellow"}}>Third slide label</h5>
                <p style={{color:"yellow"}}>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  );
}

export default HomePageCarosel;
