import { useState } from 'react';
import { Container, Row, Offcanvas, Button } from 'react-bootstrap';
import './SideBar_Styles.css';
import { MdSwitchAccessShortcutAdd } from 'react-icons/md';

function SideBars() {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);

    return (
        <div>
            <Button variant="outline-secondary" onClick={handleShow} className='quick-access-button'>
                <span style={{marginRight:'20px'}}>Quick_Access</span> <MdSwitchAccessShortcutAdd size={24}/>

            </Button>

            <Offcanvas show={show} onHide={handleShow}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Links</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container fluid>
                        {Array.from({ length: 9 }, (_, idx) => (
                            <Row key={idx} xs={2} lg={1} className={`side-bar-col${(idx % 5) + 1}`}>
                                <a className='text-styl' href="https://www.google.com/">{`Link ${idx + 1}`}</a>
                            </Row>
                        ))}
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default SideBars;
