import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import DatePicker from 'react-datepicker';
import BackButton from "../BackButton";
import GPStracker from "../CommonUtilites/GPStracker";
import UnversalSpinner from "../CommonUtilites/UniversalSpinner";

function AddNewCustomer() {

    const [response, setResponse] = useState('');
    const [deviceLocation, setDeviceLocation] = useState('');
    const [idLenght, setIdLength] = useState(true);
    const [editable, setEditable] = useState(false);
    const [customer, setCustomer] = useState({
        customerId: '',
        name: '',
        contact: '',
        emailId: '',
        address: '',
        gender: '',
        photo: null,
        dateOfBirth: null,
        zone:''
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'radio') {
            if (checked) {
                setCustomer({ ...customer, [name]: value });
            }
        } else {
            setCustomer({ ...customer, [name]: value });
        }
    };

    const handleDateChange = (name, date) => {
        setCustomer({ ...customer, [name]: date });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setCustomer({ ...customer, [name]: files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(customer.customerId.length === 10){
            try {
                const formData = new FormData();

                // Append customer data as JSON string
                formData.append('customer', JSON.stringify({
                    customerId: customer.customerId,
                    name: customer.name,
                    contact: customer.contact,
                    emailId: customer.emailId,
                    address: customer.address,
                    gpsLocation:deviceLocation,
                    gender: customer.gender,
                    dateOfBirth: customer.dateOfBirth,
                    zone: customer.zone
                }));

                // Append photo if it exists
                if (customer.photo) {
                    formData.append('photo', customer.photo);
                }
                setEditable(true);
                const response = await axios.post('http://13.233.178.111:8080/newCustomer', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setIdLength(true);
                setResponse(response.data);
                console.log(response.data); // Log the response data if needed
            } catch (error) {
                console.error('Error during form submission:', error);
            }
        } else {
            setIdLength(false);
        }
    };

    return (
        <div>
            <Form className='login-container-frame' onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label><b>Add New Customer</b></Form.Label>
                    <Form.Control
                        type="text"
                        name="customerId"
                        placeholder="CustomerID"
                        value={customer.customerId}
                        onChange={handleInputChange}
                        required
                    />
                    <Form.Text>{idLenght ? '' : <div className="error-msg">Please enter 10 Digits</div> }</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={customer.name}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        name="contact"
                        placeholder="Alt Contact"
                        value={customer.contact}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        name="zone"
                        placeholder="Zone"
                        value={customer.zone}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        name="emailId"
                        placeholder="Email ID"
                        value={customer.emailId}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        type='radio'
                        id='male'
                        name='gender'
                        label='Male'
                        checked={customer.gender === 'male'}
                        onChange={() => handleInputChange({ target: { name: 'gender', value: 'male' } })}
                    />
                    <Form.Check
                        type='radio'
                        id='female'
                        name='gender'
                        label='Female'
                        checked={customer.gender === 'female'}
                        onChange={() => handleInputChange({ target: { name: 'gender', value: 'female' } })}
                    />
                </Form.Group>

                <Form.Group className="mb-3"> 
                    <Form.Control
                        as="textarea"
                        row={5}
                        type="text"
                        name="address"
                        placeholder="Full Address"
                        value={customer.address}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Label>Photo/Image [Max 10MB] </Form.Label>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={handleFileChange}
                        aria-label="Upload Image"
                    />
                </Form.Group>

                <Form.Label>Date of Birth </Form.Label>
                <Form.Group className="mb-3">
                    <DatePicker
                        showIcon
                        toggleCalendarOnIconClick
                        isClearable
                        placeholderText="Date of Birth"
                        selected={customer.dateOfBirth}
                        onChange={(date) => handleDateChange('dateOfBirth', date)}
                    />
                </Form.Group>
                <p>User Location:- {deviceLocation} </p>
                { response.length > 0 ?
                     <div style={{ color: 'green' }}><b>{response}</b></div>
                    :
                    <>{ editable && <UnversalSpinner />}</>    
                }

                <Button variant="primary" type="submit" disabled={editable}>
                    Add
                </Button>
                <BackButton /> 
                <GPStracker setDeviceLocation={setDeviceLocation} />
            </Form>
        </div>
    );
}

export default AddNewCustomer;
