import { Card, CardGroup } from "react-bootstrap";
import contactUs_img from './ContactUs.png';
import BackButton from "../BackButton";

function ContactUs() {
    return (
        <>
            <div className="w-80">
                <CardGroup>
                    <Card>
                        <Card.Img variant="top" src={contactUs_img} height={'75vh'}/>
                        <Card.Body>
                            <Card.Title>Contact 1: HelpDesk</Card.Title>
                            <Card.Text>
                                Please contact the HelpDesk for any Query related, they can assist you on the process when you're blocked.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{textAlign:'right'}}>
                            <small className="text-muted" >
                                <a href="tel:+919666646406" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    +91 9666646406
                                </a>
                            </small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={contactUs_img} height={'75vh'}/>
                        <Card.Body>
                            <Card.Title>Contact 2: Office</Card.Title>
                            <Card.Text>
                                Contact the Office staff to join with us.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{textAlign:'right'}}>
                            <small className="text-muted">
                                <a href="tel:+918106920830" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    +91 8106920830
                                </a>
                            </small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={contactUs_img} height={'75vh'}/>
                        <Card.Body>
                            <Card.Title>Contact 3: Admin</Card.Title>
                            <Card.Text>
                                Contact the Admin if you are facing any technical issue.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer style={{textAlign:'right'}}>
                            <small className="text-muted">
                                <a href="tel:+917981277898" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    +91 7981277898
                                </a>
                            </small>
                        </Card.Footer>
                    </Card>
                </CardGroup>
            </div>
            <div className="divFlexSpan">
                <BackButton>Back</BackButton>
            </div>
        </>
    );
}

export default ContactUs;
