import { useState, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import './TostUtil.css'; // Import custom CSS for additional styling if needed

function TostUtil({ name, showProp, onClose, bgColor }) {
  const [show, setShow] = useState(showProp);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <ToastContainer position="top-center" className="p-3">
      <Toast onClose={handleClose} show={show} delay={500} autohide bg={bgColor}>
        <Toast.Header>
          <img src="" className="rounded me-2" alt="" />
          <strong className="me-auto">Product</strong>
          <small>1 Sec ago</small>
        </Toast.Header>
        <Toast.Body> "<b>{name}</b>" {bgColor === 'success' ? ' Added ' : ' Removed '} from Cart</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default TostUtil;
