import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BackButton from './BackButton';
import UnversalSpinner from './CommonUtilites/UniversalSpinner';

function AddItem() {

  const [addItem, setAddItem] = useState({
    item_id: '',
    itemName: '',
    categoryType: '',
    Description: '',
    brand: '',
    warehouse_location: '',
    original_price: '',
    selling_price: '',
    discount: '',
    gst:'',
    image: null,
    Bar_QRCode: null,
    purchaseDate: new Date(),
    expiryDate: null,
    weight: '',
    dimensions: '',
    color: '',
    average_rating: '',
    number_of_reviews: '',
    availability_stock: '',
    last_updated: '',
    warranty_info: '',
    return_policy: '',
    quantity: 0,
  });

  const [response, setResponse] = useState('');
  const [editable, setEditable] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Parse numeric fields as floats
    const parsedValue = (name === 'original_price' || name === 'selling_price'  ) 
                        ? parseFloat(value) 
                        : value;
    setAddItem({ ...addItem, [name]: parsedValue });
  };

  const handleDateChange = (name, date) => {
    setAddItem({ ...addItem, [name]: date });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setAddItem({ ...addItem, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditable(true);
    try {
        const formData = new FormData();
        Object.keys(addItem).forEach((key) => {
            if (addItem[key] instanceof File) {
                formData.append(key, addItem[key]);
            } else {
                formData.append(key, addItem[key]);
            }
        });

        const getResponse = await axios.post('http://13.233.178.111:8080/addNewItem', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        setResponse(getResponse.data);

        // Reset the form after successful submission
        if(getResponse.data === 'Item Added'){
        setAddItem({
            item_id: '',
            itemName: '',
            categoryType: '',
            Description: '',
            brand: '',
            warehouse_location: '',
            original_price: '',
            selling_price: '',
            discount: '',
            gst:'',
            image: null,
            Bar_QRCode: null,
            purchaseDate: new Date(),
            expiryDate: null,
            weight: '',
            dimensions: '',
            color: '',
            average_rating: '',
            number_of_reviews: '',
            availability_stock: '',
            last_updated: '',
            warranty_info: '',
            return_policy: '',
            quantity: 0,
        });
      }

        // Optionally, you can reset the form UI as well
        e.target.reset();

    } catch (error) {
        console.error('Error during item addition:', error);
        setResponse('Error adding item. Please try again.');
    }

    setEditable(false);
};



  return (
    <div>
      <Form className='login-container-frame' onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label><b>Add Item</b></Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Enter Item Name"
            value={addItem.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        {/* Add more Form.Group for each field */}
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            name="categoryType"
            placeholder="Category Type"
            value={addItem.categoryType}
            onChange={handleInputChange}
          />
        </Form.Group>
         <Form.Group className="mb-3">
          <Form.Control
            type="text"
            name="brand"
            placeholder="Brand"
            value={addItem.brand}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            name="warehouse_location"
            placeholder="Warehouse Location"
            value={addItem.warehouse_location}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            name="original_price"
            placeholder="Original Price"
            value={addItem.original_price}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            name="discount"
            placeholder="Discount %"
            value={addItem.discount}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            name="gst"
            placeholder="GST %"
            value={addItem.gst}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            type="number"
            name="weight"
            placeholder="Weight"
            value={addItem.weight}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            name="dimensions"
            placeholder="Dimensions"
            value={addItem.dimensions}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            name="color"
            placeholder="Color"
            value={addItem.color}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Label>Upload Image [Max 10MB] </Form.Label>
        <Form.Group className="mb-3">
          <Form.Control
            type="file"
            name="image"
            accept="image/*"
            onChange={handleFileChange}
            aria-label="Upload Image"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            isClearable
            placeholderText="Purchase Date"
            selected={addItem.purchaseDate}
            onChange={(date) => handleDateChange('purchaseDate', date)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            isClearable
            placeholderText="Expiry Date"
            selected={addItem.expiryDate}
            onChange={(date) => handleDateChange('expiryDate', date)}
          />
        </Form.Group>
          { response.length > 0  ? 
              <div style={{color:'green'}}><b>{response}</b></div> 
            :
            <>{ editable && <UnversalSpinner /> }</>
          }
        <Button variant="primary" type="submit" disabled={editable} >
          Add
        </Button>
        <BackButton /> 
      </Form>
    </div>
  );
}

export default AddItem;
