import { useState } from 'react';
import LoginForm from './Login_Form';
import { Navigate } from 'react-router-dom';

function LogInCheck() {
  const [hasAccess, setHasAccess] = useState(false);

  const handleLogIn = (eProp1) => {
    if (eProp1) {
      setHasAccess(eProp1);
    }
  };

  return (
    <>
      {!hasAccess ? (
        <LoginForm onLogin={handleLogIn} />
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
}

export default LogInCheck;
