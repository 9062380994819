import { useEffect } from "react";

function GPStracker({ setDeviceLocation }) {

    useEffect(() => {
        let watchId;

        const getDeviceLocation = () => {
            if (navigator.geolocation) {
                watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setDeviceLocation(latitude + ',' + longitude);
                    },
                    (error) => {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                console.error("User denied the request for Geolocation.");
                                break;
                            case error.POSITION_UNAVAILABLE:
                                console.error("Location information is unavailable.");
                                break;
                            case error.TIMEOUT:
                                console.error("The request to get user location timed out.");
                                break;
                            default:
                                console.error("An unknown error occurred.");
                                break;
                        }
                    },
                    { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
                );
            } else {
                console.error('Error: Device does not support GPS.');
            }
        };

        getDeviceLocation();

        return () => {
            if (watchId) {
                navigator.geolocation.clearWatch(watchId);
            }
        };

    }, [setDeviceLocation]);

    return null;
}

export default GPStracker;
