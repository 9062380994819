import { Button, Card, Form, ListGroup, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BackButton from "../BackButton";
import { IoCallOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { useState, useEffect } from "react";
import GPStracker from "../CommonUtilites/GPStracker";
import axios from "axios";

function CustomerFullDetails() {
    
    const location = useLocation();
    const customer = location.state.customer;
    const [customerObj, setCustomerObj] = useState(customer);
    const [dbResponse, setDbResponse] = useState('');
    const [show, setShow] = useState(false);
    const [deviceLocation, setDeviceLocation] = useState(customer.gpsLocation || '');

    useEffect(() => {
        setCustomerObj(prevState => ({
            ...prevState,
            gpsLocation: deviceLocation
        }));
    }, [deviceLocation]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerObj({ ...customerObj, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.put('http://13.233.178.111:8080/updateCustomerDetails', customerObj);
        setDbResponse(response.data);
        handleClose();
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className='divFlexSpan'>
                <Card style={{ width: '25rem' }}>
                    <Card.Img variant="top" src={`data:image/jpeg;base64,${customer.photo}`} />
                    <Card.Body>
                        <Card.Title>{customer.name}</Card.Title>
                        <Card.Text>
                            <b>Address:-</b>{customer.address}
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item><b>DOB: </b>{customer.dateOfBirth != null ? (customer.dateOfBirth).substring(0, 10) : ''}</ListGroup.Item>
                        <ListGroup.Item><b>Gender: </b>{customer.gender}</ListGroup.Item>
                        <ListGroup.Item><b>Mail_ID:</b> {customer.emailId}</ListGroup.Item>
                        <ListGroup.Item><b>Location:</b> {customer.gpsLocation}</ListGroup.Item>
                    </ListGroup>
                    <Card.Body>
                        <Card.Text> <b>Call:</b><IoCallOutline /> 
                            <a href={`tel:+91{customer.customerId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                +91 {customer.customerId}
                            </a>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div>               
                { dbResponse != null ? <center><p style={{color:'green'}}><strong>{dbResponse} </strong></p> </center>: ''}
            </div>
            <div className='divFlexSpan'>
                <BackButton />
                <Button variant="outline-secondary" className='backButton-indent' onClick={handleShow} ><CiEdit /> Edit</Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={customerObj.name}
                                onChange={handleChange}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Control
                                type="email"
                                name="emailId"
                                placeholder="EmailID: name@example.com"
                                value={customerObj.emailId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocation">
                            <Form.Control
                                type="text"
                                name="gpsLocation"
                                placeholder="New Location"
                                value={deviceLocation}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Control
                                as="textarea"
                                placeholder="Full Address"
                                rows={3}
                                name="address"
                                value={customerObj.address}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <GPStracker setDeviceLocation={setDeviceLocation} />
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomerFullDetails;