import { Container, Row } from "react-bootstrap";
import BackButton from "../BackButton";
import { useEffect, useState } from "react";
import axios from "axios";
import PersonTemplate from "./PersonTemplate";
import UnversalSpinner from "../CommonUtilites/UniversalSpinner";

function ActiveAgents(){

    const [agentsList, setAgentList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const getOrdersList = async () => {
            try{
                const response = await axios.get('http://13.233.178.111:8080/getActiveAgents');
                if(response.data){
                    console.log(response.data);
                    setAgentList(response.data);
                    setLoading(false);
                }
            } catch (error){
                console.log(error);
            }
        }
        getOrdersList();
    }, []);

    return(
        <>
        <Container>
            <Row className="d-flex justify-content-start" style={{marginTop:'30px'}}>
                { loading ? 
                    <> <UnversalSpinner /> </>
                    :
                    <>
                    { agentsList.length > 0 ? 
                        <>
                        { agentsList.map((agent)=> (<PersonTemplate details={agent}/>))}
                        
                                <div className='divFlexSpan'>
                                    <BackButton />
                                </div>
                        </>
                        : 
                        <>
                            <div className='divFlexSpan'>
                                <p align="center"><strong> Active Agents Not Found </strong></p>
                            </div>
                            <div className='divFlexSpan'>
                                <BackButton />
                            </div>
                        </>
                    }
                    </>                   
                }
            </Row>
        </Container>
        </>
    );
}

export default ActiveAgents;