import { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import { IoArrowBackSharp } from "react-icons/io5";

function ShowBOM({cartItems, bom}) {
    
    let sno = 1;
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalgst, setTotalgst] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
  
    useEffect(() => {
        console.log(cartItems);
        const totalQuantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);
        setTotalQuantity(totalQuantity);
    }, [cartItems]);

    useEffect(() => {
        const totalCost = cartItems.reduce((sum, item) => sum + (item.sellingPrice * item.quantity), 0).toFixed(2);
        setTotalCost(totalCost);
    }, [cartItems]);

    useEffect(() => {
        const totalDisc = cartItems.reduce((sum, item) => sum + (item.discount > 0 ? (item.sellingPrice*item.discount*0.01) * item.quantity : 0), 0).toFixed(2);
        setTotalDiscount(totalDisc);
    }, [cartItems]);

    useEffect(() => {
        const gstTotal = cartItems.reduce((sum, item) => sum + ((item.sellingPrice - (item.sellingPrice*item.discount*0.01)) * item.quantity * item.gst * 0.01), 0).toFixed(2);
        setTotalgst(gstTotal);
    }, [cartItems]);

    const finalCost = (parseFloat(totalCost) - parseFloat(totalDiscount) + parseFloat(totalgst)).toFixed(2);

    return (
        <div style={{paddingLeft:"2vw", paddingRight:"1vw"}}>
        <Container id="print-container" className="d-flex flex-column align-items-center">
            <p><b>Bill Of Materials</b></p>
            <div style={{paddingLeft:"2vw", paddingRight:"1vw"}}>
            <Table striped bordered hover responsive className="w-90">
                <thead align="center">
                    <tr>
                        <th><center>S.NO</center></th>
                        <th>ItemName</th>
                        <th>GST</th>
                        <th>Quantity</th>
                        <th>ItemPrice</th>
                        <th>Discount</th>
                        <th>Cost</th>

                    </tr>
                </thead>
                <tbody>
                    {cartItems.map(
                        (o) => (
                            o.quantity > 0 ? (
                                <tr key={o.itemId}>
                                    <td align="center">{sno++}</td>
                                    <td align="left">{(o.name).replaceAll(' ','')}</td>
                                    <td align="right">{o.gst}%</td>
                                    <td align="center"><b>{o.quantity}</b></td>
                                    <td align="right">{(o.sellingPrice).toFixed(2)}</td>
                                    <td align="right">{(o.sellingPrice * o.discount * 0.01).toFixed(2)}</td>
                                    <td align="right"><b>{((o.sellingPrice - (o.sellingPrice * o.discount * 0.01)) * o.quantity).toFixed(2)}</b></td>
                                </tr>
                            ) : null
                        )
                    )}

                    <tr align="center">
                        <td colSpan={3}>Total</td>
                        <td width={"w-100"}><b>{totalQuantity}</b></td>
                        <td width={"w-100"} align="right"><b>{ totalCost }</b></td>
                        <td width={"w-100"} align="right"><b>{totalDiscount}</b></td>
                        <td colSpan={1}>-</td>
                    </tr>
                    <tr align="center">
                        <td colSpan={5} width={"w-100"}><i><b>GST</b></i></td>
                        <td colSpan={2} width={"w-100"} align="right">Rs. <b>{totalgst}</b>/-</td>
                    </tr>
                    <tr>
                        <td colSpan={5} width={"w-100"}><center><b>GrandTotal</b></center></td>
                        <td colSpan={2} width={"w-100"} align="right">Rs. <b>{finalCost}/-</b></td>
                    </tr>


                </tbody>
            </Table>
            </div>
            </Container>
            <div className='divFlexSpan'>
                <Button className='backButton-indent' onClick={ () => bom(false)}> <IoArrowBackSharp style={{marginRight:'5px'}} /> Back </Button>
                <Button className='backButton-indent' onClick={ () => bom(true)}> Confirm</Button> 
            </div>

        </div>
    );
}

export default ShowBOM;
