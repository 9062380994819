import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Security/AuthContext';
import { GrLogin, GrLogout } from "react-icons/gr";
import UnversalSpinner from './CommonUtilites/UniversalSpinner';

function LoginForm({ onLogin }) {

  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [connectionError, setConnectionError] = useState('');
  const [loginData, setLoginData] = useState({
    mobileNumber: '',
    password: ''
  });
  const navigate = useNavigate();
  const { login } = useAuth();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const appUrl=process.env.REACT_APP_API_URL;
      console.log("env param are " + {appUrl}+'/checkUserCredentials')

      const response = await axios.post('http://13.233.178.111:8080/checkUserCredentials', loginData);
      
      console.log(response.data); // Handle the response data as per your application's requirements
      if(response.data){
        setLoading(false);
        onLogin(response.data);
        login(response.data);
      }
      else{
        setAccountNotFound(true);
      }

    } catch (error) {
      setConnectionError('BackEnd Error, Please try after sometime... ');
      console.error('Error during login:', error);
    }
  };

  const handleNewAccountClick = () => {
    navigate('/createAccount');
  };

  return (
    <div>
      
      <Form className='login-container-frame'>
        <Form.Group className="mb-3" >
          <Form.Label>Login ID</Form.Label>
          <Form.Control md={10}
            type="text"
            name="mobileNumber"
            placeholder="Enter mobile number"
            value={loginData.mobileNumber}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={ showPass ? "text" : "password" } 
            name="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Show Password" onClick={() => setShowPass(!showPass)}/>

          <Form.Text className="text-muted">
            { loading && !accountNotFound ? <UnversalSpinner /> : <> </>}
            {accountNotFound ? 
              <div className='error-msg'>Invalid Login details</div> : 
              <> 
              { connectionError != null ? <
                  div className='error-msg'>{connectionError}</div> : 
                  <> </> }
              </>
            }
          </Form.Text>

        </Form.Group>

        <Button variant="primary" type="submit" onClick={handleLogin} disabled={loading}>
        <GrLogin /> Login
        </Button>
        
        {accountNotFound ? 
          <Button variant='primary' className='custom-indent-space'>Forgot Password</Button>: ''}

        <Button variant="primary" className='custom-indent-space' onClick={handleNewAccountClick}>
        <GrLogout /> Create Account
        </Button> 
      </Form>
    </div>
  );
}

export default LoginForm;