import React, { useState } from "react";
import { Button, Figure, FormControl } from "react-bootstrap";
import './viewItems.css';

const OrderCartTemplate = ({ item }) => {
  const width = 250; // Set your desired width
  const height = (width / 16) * 9; // Calculate height based on 16:9 aspect ratio
  const [quantity, setQuantity] = useState(item.quantity);
  const [isEditing, setIsEditing] = useState(false);

  const handleIncCount = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      item.quantity = newQuantity;
      return newQuantity;
    });
  };

  const handleDecCount = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > 1) {
        const newQuantity = prevQuantity - 1;
        item.quantity = newQuantity;
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  const handleInputChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setQuantity(newQuantity);
      item.quantity = newQuantity;
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  // Check if item is undefined or doesn't have the image property
  if (!item || !item.image) {
    return <div>Error: Item data is missing or invalid.</div>;
  }

  return (
    <Figure key={item.itemId} className="figure-wrapper">
      <Figure.Image
        thumbnail
        style={{ width: `${width}px`, height: `${height}px`, margin: "5px" }}
        src={`data:image/jpeg;base64,${item.image}`}
        alt={item.name}
      />
      <div className="divFlexSpan">
        <Figure.Caption>
          <strong>{item.name} :</strong>
          <strong style={{marginLeft:"3px"}}> ₹ {(item.sellingPrice * quantity).toFixed(2)}</strong>/-
        </Figure.Caption>
      </div>
      <div className="quantity-buttons divFlexSpan">
        <Button variant="outline-secondary" onClick={handleDecCount}>
          -
        </Button>
        {isEditing ? (
          <FormControl
            type="text"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus 
            className="quantity-width"
            style={{textAlign: "center", display: "inline-block" }}
          />
        ) : (
          <Button
            variant="outline-secondary"
            onClick={() => setIsEditing(true)}
          >
            Quantity = <strong>{quantity}</strong>
          </Button>
        )}
        <Button variant="outline-secondary" onClick={handleIncCount}>
          +
        </Button>
      </div>
    </Figure>
  );
};

export default OrderCartTemplate;
