import { Card } from "react-bootstrap"; 
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton";
import image1 from './image1.png';
import image2 from './image2.png';
import image3 from './image3.png';
import '../AdminServices/adminOverview.css'; // Import the CSS file

function AdminServices(){
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex justify-content-center image-container" style={{marginTop:'5vh'}}>   
        <Card className="bg-success text-white indent-position" onClick={() => navigate('/adminNewServices')}>
          <Card.Img src={image1} className="image-fixed-size" alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title className="card-title">New Services</Card.Title> 
            <Card.Text className="card-text">Our Opportunities</Card.Text>
          </Card.ImgOverlay>
        </Card>

        <Card className="bg-warning text-white indent-position" onClick={() => navigate('/adminActiveServices')}>
          <Card.Img src={image2} className="image-fixed-size" alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title className="card-title">Active Services</Card.Title>
            <Card.Text className="card-text">Our Efforts make us Success</Card.Text>
          </Card.ImgOverlay>
        </Card>

        <Card className="bg-info text-white indent-position" onClick={() => navigate('/adminDetailServices')}>
          <Card.Img src={image3} className="image-fixed-size" height={250} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title className="card-title" style={{color:"green"}}><strong>Detailed Services</strong></Card.Title>
            <Card.Text className="card-text" style={{color:"green"}}>Our Achievements</Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>

      <div className="indent-container">
        <BackButton />
      </div>
    </>
  );
}

export default AdminServices;
