import axios from "axios";
import { useEffect, useState } from "react";
import CustomerTemplate from "./CustomerTemplate";
import BackButton from "../BackButton";
import UnversalSpinner from "../CommonUtilites/UniversalSpinner";

function AllCustomersList() {

    const [loading, setLoading] = useState(true);
    const [customersList, setCustomersList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCustomersList, setFilteredCustomersList] = useState([]);

    useEffect(() => {
        const getCustomersList = async () => {
            try {
                const response = await axios.get('http://13.233.178.111:8080/getAllCustomers');
                console.log(response.data);
                setCustomersList(response.data);
                setFilteredCustomersList(response.data); // Initialize filtered list
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        getCustomersList();
    }, []);

    function searchCustomer(searchTerm){
        // Filter the customer list based on the search term
        const filtered = customersList.filter(customer =>
            String(customer.customerId).toLowerCase().includes(String(searchTerm).toLowerCase())
        );
        console.log(filtered);
        setFilteredCustomersList(filtered);
    };

    return (
        <div style={{marginTop:'50px'}}>
            { loading ? 
                <>
                    <UnversalSpinner />
                </>
                :
                <>
                <div className="divFlexSpan">
                    <input 
                        type="text" 
                        placeholder="Search Customers..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    /> 
                    <input type="button" onClick={()=>searchCustomer(searchTerm)} value="Search" />
                </div>
                { filteredCustomersList.length > 0 ?
                    <div className="divFlexSpan">
                        {filteredCustomersList.map(
                            (customer) => (<CustomerTemplate key={customer.id} details={customer} />)
                        )}
                    </div>
                    :
                    <div className="divFlexSpan" style={{marginTop:"3vh"}}>
                        <p><strong>No User Found</strong></p>
                    </div>
                }
                <div className="divFlexSpan">
                    <BackButton />
                </div>
                </>
            }
        </ div>
    );
}

export default AllCustomersList;
