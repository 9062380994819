import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container, Row, Table } from "react-bootstrap";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";
import UnversalSpinner from "./CommonUtilites/UniversalSpinner";

function TotalOrdersList() {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const viewOrderDetails = (orderId, customerId) => {
        navigate('/orderFullDetails', { state: { orderId, customerId } });
    }

    useEffect(() => {
        const getOrdersList = async () => {
            try {
                const response = await axios.get('http://13.233.178.111:8080/getAllOrders');
                if (response.data != null) {
                    setOrders(response.data);
                    console.log(response.data);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getOrdersList();
    }, []);

    return (
        <>
            <Container style={{marginTop:'30px'}}>
                <Row className="d-grid gap-2">
                    <div style={{ maxHeight: "90vh", overflowY: "auto", position: "relative" }}>
                        <Table striped hover responsive className="w-100">
                            <thead align="center" style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1000 }}>
                                <tr>
                                    <th><center>OrderID</center></th>
                                    <th>OrderDate</th>
                                    <th>CustomerID</th>
                                    <th>Status</th>
                                    <th>PaymentType</th>
                                    <th>PaymentMode</th>
                                    <th>Agent</th>
                                </tr>
                            </thead>
                            <tbody align="center">
                            { loading ? 
                                <tr align="center"> 
                                    <td colSpan={7}><UnversalSpinner /></td>
                                </tr> 
                                : <>
                                {orders.map((order) => (
                                    <tr key={order.orderId}>
                                        <td>
                                            <Button variant="success">ID=<strong>{order.orderId}</strong></Button>
                                        </td>
                                        <td>
                                            <Button className="custom-margin" variant="outline-primary"><strong>{(order.orderCreatedTime.substring(0, 10)).replaceAll('-', '/')}</strong></Button>
                                        </td>
                                        <td>
                                            <Button className="w-100 custom-margin" variant="success" onClick={() => viewOrderDetails(order.orderId, order.customerId)}>
                                                {order.customerId}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className="w-100 custom-margin"
                                                variant={order.orderStatus === 'Delivered' ? 'success' : (order.orderStatus === 'Canceled' ? 'outline-warning' : 'outline-primary')}
                                                onClick={null}>
                                                <strong>{order.orderStatus}</strong>
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className="w-100 custom-margin" variant="outline-secondary">
                                                {order.paymentMode == null ? 'Cash' : <strong>{order.paymentMode}</strong>}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className="w-100 custom-margin" variant="outline-secondary">
                                                {order.paymentStatus == null ? 'Pending' : <strong>{order.paymentStatus}</strong>}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className="w-100 custom-margin" style={order.deliveryAgent ? {} : { color: 'red', fontWeight: 'bold' }} variant="outline-secondary">
                                                {order.deliveryAgent == null ? 'UnAssigned' : <strong>{order.deliveryAgent}</strong>}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                
                                </>
                                }
                                </tbody>                            
                        </Table>
                    </div>
                </Row>
            </Container>

            <div className="divFlexSpan">
                <BackButton />
            </div>
        </>
    );
}

export default TotalOrdersList;