import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import axios from 'axios';
import OrderCartTemplate from './OrderCartTemplate';
import { FaCartArrowDown } from "react-icons/fa";
import ShowBOM from './ViewItemsPage/ShowBOM';
import { useNavigate } from 'react-router-dom';
import UnversalSpinner from './CommonUtilites/UniversalSpinner';
import { IoArrowBackSharp, IoReceipt } from 'react-icons/io5';
import './addtocart.css'; // Import the CSS file containing the styles

function AddToCart({cartItems, back}) {

  const [loading, setLoading] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [orderId, setOrderId] = useState(0);
  const [bom, setBom] = useState(false);
  const [show, setShow] = useState(false);
  const [orderButtonEnable, setOrderButtonEnable] = useState(false);
  const [paymentMode, setPaymentMode] = useState('Cash On Delivery');
  const modeOfPayments = ['Cash On Delivery', 'UPI - PhonePe,Gpay'];
  const [customersList, setCustomersList] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const updateCustomerId = (e) => {
    const value = e.target.value;
    // Allow only digits and ensure the length is not more than 10
    if (/^\d{0,10}$/.test(value)) {
      setCustomerId(value);
      if(value.length === 10){
        setOrderButtonEnable(true);
      }else{
        setOrderButtonEnable(false);
      }

      // Filter the suggestions based on the input value
      if (value) {
        const filtered = customersList.filter(id => id.startsWith(value));
        setFilteredSuggestions(filtered);
      } else {
        setFilteredSuggestions([]);
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setCustomerId(suggestion);
    setFilteredSuggestions([]);
    setOrderButtonEnable(true);
  };
  
  const handelPlaceOrder = (pro) => {
    if(pro) {
      setBom(false);
      setShow(true);
    } else {
      setBom(false);
    }
  }


const handelCreateOrderAPI = async (e) => {
    console.log(customerId);
    setOrderButtonEnable(true);
    setLoading(true);
    e.preventDefault();

    const reqItems = cartItems.map((item) => {
      item.image = null;
      return item;
    });

    try{
       const response = await axios.post('http://13.233.178.111:8080/createOrder', 
                                          {items: reqItems, id: customerId , paymentMethod: paymentMode}, 
                                          {
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }});
        console.log("Order Placed Status : " + response.data);
        if(response.data){
            setLoading(false);
            setOrderId(response.data);
            setShow(false);
            setOrderPlaced(show);   
        }
    }
    catch(error) {
        console.log(error);
    }
};

useEffect(() => {
  const getCustomersList = async () => {
      try {
          const response = await axios.get('http://13.233.178.111:8080/getAllCustomersIds');
          console.log(response.data);
          setCustomersList(response.data);
          // setLoading(false);
      } catch (error) {
          console.log(error);
      }
  }
  getCustomersList();
}, []);

  return (
    <div style={{marginTop:'10px'}}>
      { orderPlaced || bom ? 
        <>
          { bom ? 
          <ShowBOM cartItems={cartItems} bom={handelPlaceOrder} /> 
          : 
          <Container>
            <Row>
              <Col>
              <div className='divFlexSpan'>
                  <strong style={{color:'green'}}>Order placed Successfully.........!</strong>
              </div>
              </Col>
            </Row>
            <Row>
              <Col>
              <div className='divFlexSpan'>
                <Button onClick={() => { navigate('/orderFullDetails', {state : {orderId, customerId}});}}>
                  Show OrderDetails
                </Button>               
              </div>
              </Col>
            </Row>
            
          </Container>

          }
        </>
      :
      <div>
      <div className='divFlexSpan'>
          {cartItems.map((item) => (<OrderCartTemplate item={item} > </OrderCartTemplate>))}
          </div>
          <div className='divFlexSpan'>
              <Button className='fixed-button-left'  onClick={() => back()}> <IoArrowBackSharp style={{marginRight:'5px'}} />Back</Button> 
              <Button variant='primary' 
                  className='fixed-button-right' 
                  onClick={() => setBom(true)} 
                  disabled={orderPlaced}> <IoReceipt style={{marginRight:'5px'}} /> Show Bill</Button>
            </div>
        </div>
      }


      <div>
      <Modal show={show} >
        <Modal.Header >
          <Modal.Title>Order Details</Modal.Title>
          <Button variant="close" onClick={() => setShow(false)} aria-label="Close"></Button>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 10-digit mobile number"
                value={customerId}
                onChange={updateCustomerId}
                autoFocus
              />
              {/* Suggestions List */}
              {filteredSuggestions.length > 0 && (
                <div className="suggestions-list">
                  {filteredSuggestions.map((suggestion, index) => (
                    <div key={index} className="suggestion-item" onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion}
                    </div>
                  ))}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3"> 
                    <Form.Control
                        as="textarea"
                        row={5}
                        type="text"
                        name="address"
                        placeholder="Full Address"
                        // value={customer.address}
                        // onChange={handleInputChange}
                    />
                </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Payment Type</Form.Label>
              <Form.Select aria-label="Payment Status Select" onChange={(e) => setPaymentMode(e.target.value)}>
                                    {modeOfPayments.map(Ptype => (
                                        <option value={Ptype} key={Ptype}>{Ptype}</option>
                                    ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          { loading ? <UnversalSpinner />  : <> </> }
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" 
                  onClick={handelCreateOrderAPI}
                  disabled = { !orderButtonEnable }                  
                  >
            <FaCartArrowDown style={{marginRight:'5px'}}/>
            Place Order
          </Button>
        </Modal.Footer>
      </Modal>
      </div>

    </div>
  );
}

export default AddToCart;
