import { Button, Form } from "react-bootstrap";
import BackButton from "../BackButton";
import { useState } from "react";
import axios from "axios";
import DatePicker from 'react-datepicker';
import UnversalSpinner from "../CommonUtilites/UniversalSpinner";


function AddNewAgent(){

    const [response, setResponse] = useState('');
    const [editable, setEditable] = useState(false);

    const [agent, setAgent] = useState({
        name: '',
        contact: '',
        designation: '',
        salary: '',
        photo: null,
        dateOfJoining: new Date(),
        dateOfLeaving: null
      });
    
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
                setAgent({ ...agent, [name]: value });
      };
    
      const handleDateChange = (name, date) => {
        setAgent({ ...agent, [name]: date });
      };
    
      const handleFileChange = (e) => {
        const { name, files } = e.target;
        setAgent({ ...agent, [name]: files[0] });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setEditable(true);
        try {
            const formData = new FormData();
    
            Object.keys(agent).forEach((key) => {
                if (key === 'photo') {
                    formData.append(key, agent[key]);
                } else {
                    formData.append(key, agent[key]);
                }
            });
    
            const getResponse = await axios.post('http://13.233.178.111:8080/createNewAgent', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setResponse(getResponse.data);
        } catch (error) {
            console.error('Error during item addition:', error);
            setResponse('Error adding item. Please try again.');
        }
    };


    return(
        <div>
            <Form className='login-container-frame' onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                <Form.Label><b>Create New Agent</b></Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    placeholder="Agent Name"
                    value={agent.name}
                    onChange={handleInputChange}
                    required
                />
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    name="contact"
                    placeholder="Contact"
                    value={agent.contact}
                    onChange={handleInputChange}
                />
                </Form.Group>
                <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    name="designation"
                    placeholder="Designation"
                    value={agent.designation}
                    onChange={handleInputChange}
                />
                </Form.Group>
                <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    name="salary"
                    placeholder="Salary (Ex:- 10,000/-)"
                    value={agent.salary}
                    onChange={handleInputChange}
                />
                </Form.Group>

                <Form.Label>Photo/Image [Max 10MB] </Form.Label>
                <Form.Group className="mb-3">
                <Form.Control
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={handleFileChange}
                    aria-label="Upload Image"
                />
                </Form.Group>
                <Form.Group className="mb-3">
                <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    placeholderText="Date of Joining"
                    selected={agent.dateOfJoining}
                    onChange={(date) => handleDateChange('dateOfJoining', date)}
                />
                </Form.Group>
                <Form.Group className="mb-3">
                <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    placeholderText="Leaving Date"
                    selected={agent.dateOfLeaving}
                    onChange={(date) => handleDateChange('dateOfLeaving', date)}
                />
                </Form.Group>
                { response.length > 0 ?
                  <div style={{color:'green'}}><b>{response}</b></div>
                :
                    <> { editable && <UnversalSpinner /> }</>
                }
                <Button variant="primary" type="submit" disabled={editable}>
                        Add
                </Button>
                <BackButton />
            </Form>
        </div>
    );

}
export default AddNewAgent;