
function HomePageContent(){
    
    return(
        <div style={{marginLeft:'5vw'}}>
                <h1>Presentin One India Company</h1>
                <ul>
                    <li>
                        <strong>Unified Vision:</strong>
                        <p>Pan-Indian Identity: The company should embody a vision that transcends regional, linguistic, and cultural boundaries, representing the collective aspirations and potential of India as a whole.</p>
                    </li>
                    <li>
                        <strong>Diverse Offerings:</strong>
                        <p>Inclusivity: Offer products or services that cater to the diverse needs of the Indian population, ensuring that every segment of society feels represented and valued.</p>
                    </li>
                    <li>
                        <strong>Nationwide Reach:</strong>
                        <p>Accessibility: Aim to provide your offerings across the entire country, from metropolitan cities to rural areas, ensuring that everyone, regardless of location, can benefit from what your company has to offer.</p>
                    </li>
                    <li>
                        <strong>Cultural Integration:</strong>
                        <p>Respect for Heritage: While promoting unity, the company should also respect and integrate India's rich cultural heritage, blending traditional values with modern innovations.</p>
                    </li>
                    <li>
                        <strong>Social Impact:</strong>
                        <p>Community Building: Focus on initiatives that contribute to the social and economic development of communities across India, fostering a sense of national unity and progress.</p>
                    </li>
                    <li>
                        <strong>Sustainability and Growth:</strong>
                        <p>Long-term Vision: Develop sustainable business practices that contribute to the long-term growth of the nation, ensuring that the company’s success is intertwined with the prosperity of the country.</p>
                    </li>
                    <li>
                        <strong>Technological Innovation:</strong>
                        <p>Bridging Gaps: Use technology to bridge regional disparities, offering solutions that can be adapted and scaled to different parts of the country, helping to unify and uplift diverse communities.</p>
                    </li>
                </ul>
        </div>
    );
}

export default HomePageContent;